import React from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import ScrollAnimation from "react-animate-on-scroll"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./OffplanDevelopers.scss"
import { Link } from "gatsby"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanDevelopers = ({ developers, isOffplanLanding,moduleData, isPreviewEnabled }) => {
  const { isMobile } = useDeviceMedia()
  const OffplanLanding = isOffplanLanding ? "offplan_developers" : ""

  const developerList = isOffplanLanding?developers:developers?.developer_logo
  return (
    <section className={`offplan-developers-wrapper ${OffplanLanding}`}>
      <div className="offplain-developer-module">
        <h2 className="title">{moduleData?.title}</h2>
        {(moduleData?.description?.data?.description || (isPreviewEnabled &&moduleData?.description)) && (
          <p className="description">
            <ContentModule
              Content={moduleData?.description?.data?.description || moduleData?.description}
            />
          </p>
        )}
        <CustomSlider
          noDots
          showArrows
          iconchange
          showMeThree
          slidecount={isMobile ? 1.8 : 6}
          className="offplan-developer-slider"
        >
          {developerList?.length > 0 &&
            developerList?.map((logo, index) => {
              return (
                <div
                >
                  <Link to={`${logo?.custom_link}`}>
                   <div className="image-list-section">
                    <img src={logo?.image?.url} alt="developer logo" />
                    </div>
                  </Link>
                </div>
              )
            })}
        </CustomSlider>
      </div>
    </section>
  )
}

export default OffplanDevelopers
