import React, { useState } from "react"
import { Container, Modal } from "react-bootstrap"
import GGFXImage from "../../modules/GGFXImage"
import ScrollAnimation from "react-animate-on-scroll"
import "./OffplanNewBanner.scss"
import PlayVideo from "../PlayVideo/PlayVideo"
import FormFields from "../../../static/forms/book_consultation.json"
import DefaultForm from "../../components/forms/default-form-layout"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const OffplanNewBanner = ({ pageData, ratingData, isPreviewEnabled,queryValue }) => {
  const [video, setVideo] = useState(null)
  const [show, setShow] = useState(queryValue === "book-a-consultation" ? true :false)

  const handleModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const {isMobile} = useDeviceMedia()

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const bannerData = pageData?.banner
  const imagename = "page.banner_section_banner_image.offplan_banner_image"
  return (
    <div className="offplan-newbanner-wrapper">
      <div className="layout-padding-top"></div>
      <Container>
        <ScrollAnimation
          animateIn="animate__slideInUp"
          animateOnce
          delay={100}
          offset={10}
        >
          <div className="sections-offplan">
            <div className="left-banner-section">
            {/* {(isMobile&&pageData?.mobile_image?.url)?
              <GGFXImage
              ImageSrc={pageData?.mobile_image}
              altText={`${
                bannerData?.alternativeText
                  ? bannerData?.alternativeText
                  : bannerData.banner_title
              } banner`}
              imagetransforms={pageData?.ggfx_results}
              renderer="srcSet"
              imagename={"page.mobile_image.banner_mobile_image"}
              strapiID={pageData?.strapi_id}
           />
          : */}
              <GGFXImage
                ImageSrc={bannerData?.image}
                altText={`${
                  bannerData?.alternativeText
                    ? bannerData?.alternativeText
                    : bannerData.banner_title
                } banner`}
                imagetransforms={pageData?.ggfx_results}
                renderer="srcSet"
                imagename={imagename}
                strapiID={pageData?.strapi_id}
              />
              {/* } */}
              {bannerData?.video_url && (
                <button
                  className="play-btn"
                  onClick={() => setVideo(!video)}
                ></button>
              )}
            </div>

            <div className="right-banner-section">
              <div className="content-sections">
                <h1>{bannerData?.banner_title}</h1>
                {(bannerData?.banner_content?.data?.banner_content || (isPreviewEnabled && bannerData?.banner_content))&&
                <p className="description">
                  <ContentModule
                    Content={bannerData?.banner_content?.data?.banner_content || bannerData?.banner_content}
                  />
                </p>
                }
                <div className="banner-cta-section">
                  {
                  bannerData?.cta_1_title && (
                    <PreviewCTALink
                      class="button button-filled-green"
                      link={
                        bannerData.cta_1_link
                          ? bannerData.cta_1_link
                          : { external_link: bannerData?.cta_1_custom_link }
                      }
                      title={bannerData.cta_1_title}
                      target_window={
                        bannerData?.cta_1_custom_link?.includes("http")
                          ? "new_window"
                          : bannerData.cta_1_link?.target_window
                      }
                    />
                  )}
                  {bannerData?.cta_2_custom_link === "$consultant-form"?
                  <>
                  <button onClick={()=>handleModal()} className="button button-outline-grey">
                    {bannerData.cta_2_title}
                  </button>
                  <Modal
                    show={show}
                    // onHide={handleClose}
                    backdrop="static"
                    centered
                    dialogClassName="modal-popup-form"
                  >
                    <Modal.Body>
                      <div className="popup-form-wrapper">
                        <div
                          className="close-modal"
                          onClick={() => handleClose()}
                        >
                          <i className="icon black-cancel-icon"></i>
                        </div>
                        <div>
                        <DefaultForm
                          fields={FormFields}
                          sourceUrl={pageurl}
                          phone_id_value={"offplan_consulation-button"}
                          formTitle={"Book a Consultation"}
                          to_email_id={
                            "info@hausandhaus.com, amel@hausandhaus.com, raizul@hausandhaus.com, emma@hausandhaus.com, hauswebsiteleads@gmail.com, off.plan.consulatation@in.parseur.com"
                          }
                        />
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                
                  </>
                  
                  :
                  
                  
                  bannerData?.cta_2_title && (
                    <PreviewCTALink
                      class="button button-outline-grey"
                      link={
                        bannerData.cta_2_link
                          ? bannerData.cta_2_link
                          : { external_link: bannerData?.cta_2_custom_link }
                      }
                      title={bannerData.cta_2_title}
                      target_window={
                        bannerData?.cta_2_custom_link?.includes("http")
                          ? "new_window"
                          : bannerData.cta_2_link?.target_window
                      }
                    />
                  )}
                </div>

                <div className="review-section">
                  <span className="rating-star_icon">
                    <i className="icon new-review-icon" />
                  </span>
                  <span>
                    <b>{ratingData?.offplan_rating}</b>
                    {ratingData?.offplan_ratetext}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </Container>

      <PlayVideo
        isOpen={video}
        stopPlay={setVideo}
        videoId=""
        isCloseFunction={setVideo}
        videourl={bannerData?.video_url}
      />
    </div>
  )
}

export default OffplanNewBanner
